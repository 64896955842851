import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { NewsletterIcon } from 'src/components/newsletter'
import PageHead from 'src/components/head/PageHead'
import Heading from 'src/components/heading/Heading'

import { HELPERS_URL } from 'src/constants'

// Styles & Images
import 'static/newsletter/scss/styles.scss'
import 'src/components/newsletter/Unsubscribe/scss/Form.scss'

const page = 'waitlists-reject'

// Partials
const Background = () => (
  <>
    <div className="x-page-newsletter__background" />
    <div className="x-page-newsletter__background-x-container">
      <img src="/img/header-x-long.svg" />
    </div>
  </>
)

const DefaultHeadings = ({ page, formState }) => (
  <div className="x__headings-container--animating">
    <div className="x__heading">
      <Heading page={page} section={formState} />
    </div>
  </div>
)

/* Don't like having this duplicated, but animating won't work any other way */
const SubmittedHeadings = ({ page, formState }) => (
  <div className="x__headings-container--submitting">
    <div className="x__heading">
      <Heading page={page} section={formState} />
    </div>
    <div className="x__subheading">
      <Heading type="h2" page={page} section={formState} />
    </div>
  </div>
)

// Main component:
const UnsubscribeModal = ({ location }) => {
  const [submitting, setSubmitting] = useState(false)
  const [formState, setFormState] = useState('initializing')

  const formLoading = formState === 'loading'
  const formSubmitted = formState === 'success' || formState === 'invalid' || formState === 'error'

  const [animate, setAnimate] = useState(false)

  useEffect(() => setTimeout(setAnimate(true), 1), [])

  /* show state message */
  const handleMessage = (state) => {
    setFormState(state)
    setTimeout(() => setSubmitting(true), 100)
  }

  /* submitting */
  const handleSubmit = ({ userId, waitlistId }) => {
    /* Set state to 'loading' while validating */
    if (formLoading) return

    /* Show message if 'id' is ok */
    setFormState('loading')

    /* fetch submit */
    fetch(`${HELPERS_URL}/users/waitlists-cancel-subscription`, {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ userId, waitlistId }),
    })
      .then(async (response) => {
        const success = response.status === 200
        const invalid = response.status === 400
        /* Notice that update was successful or if update fails */
        if (success) handleMessage('success')
        else handleMessage(invalid ? 'invalid' : 'error')
      })
      .catch((err) => {
        /* Notice if update fails globally */
        console.log(err)
        handleMessage('error')
      })
  }

  /* get userId if URL has params and auto-unsubscribe */
  useEffect(() => {
    const userId = location.search && new URL(location.href).searchParams.get('userId')
    const waitlistId = location.search && new URL(location.href).searchParams.get('waitlistId')

    if (!userId || !waitlistId) handleMessage('error')

    if (userId) {
      handleSubmit({ userId, waitlistId })
    } else {
      setTimeout(() => handleMessage('error'), 2000)
    }
  }, [])

  return (
    <div
      className={classNames('x-page-newsletter', {
        'x-page-newsletter--animating': animate,
      })}
    >
      <Background />

      <div className="x-page-newsletter__content">
        <NewsletterIcon animate={animate} />

        <div
          className={classNames('x__unsubscribe-form', {
            'x__unsubscribe-form--animating': animate && !submitting,
            'x__unsubscribe-form--submitting': submitting,
          })}
        >
          {!formSubmitted ? (
            <DefaultHeadings page={page} formState={formState} />
          ) : (
            <SubmittedHeadings page={page} formState={formState} />
          )}
        </div>
      </div>
    </div>
  )
}

// Main component props:
UnsubscribeModal.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
}

UnsubscribeModal.defaultProps = {}

export default UnsubscribeModal

// <head> component:
export function Head() {
  return <PageHead page="waitlists-reject" noindex />
}
